import { ReactNode } from "react"
import useAccount from "~/hooks/use-account"
import { SidebarTrigger } from "../ui/sidebar"
import { Separator } from "../ui/separator"
import AuthButtons from "../auth-buttons"

export default function Header({
  children,
}: {
  children: ReactNode
  goBack?: boolean
}) {
  const { account } = useAccount()

  return (
    <header className="sticky w-full top-0 z-[48] bg-background border-b md:border-none flex flex-col">
      {!account && <div className="sm:hidden pt-2">{<AuthButtons />}</div>}
      <div className="w-full justify-between flex items-center min-h-14">
        <div className="flex items-center gap-2">
          <SidebarTrigger />
          <Separator orientation="vertical" className="mr-2 h-4" />
        </div>
        <div className="flex-1 flex">{children}</div>
        <div className="ml-auto hidden sm:flex items-center justify-end">
          {!account && <AuthButtons />}
        </div>
      </div>
    </header>
  )
}
