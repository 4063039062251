import { useLoginDialog } from "~/stores/dialog-store"
import { Button } from "./ui/button"

export default function AuthButtons() {
  const { openLogin, openRegister } = useLoginDialog()
  return (
    <div className="flex gap-2">
      <Button
        onClick={openLogin}
        className="w-full sm:w-fit"
        variant="secondary"
      >
        Sign in
      </Button>
      <Button onClick={openRegister} className="w-full sm:w-fit">
        Register
      </Button>
    </div>
  )
}
